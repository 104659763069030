<template>
    <div id="dates" :style="{'background': 'url(' + require('@/assets/img/background-dates.png') + ') no-repeat center center fixed'} ">
        <Dates></Dates>
    </div>
</template>

<script>
import Dates from './Dates/Dates';
//import Artistes from './Main/Artistes';

export default {
    name: 'DatePage',
    components: {
        Dates
    }
}
</script>