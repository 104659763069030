<template>
	<div v-if="info" id="date" class="container-fluid">
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<h2>Prochaines dates</h2>
					<div class="divider-c"> <span></span></div>
				</div>
			</div>
		</div>
		<table class="table table-lg"> <!-- Date - Lieu/Ville - Commentaire - Google map -->
			<tbody>
				<tr
				v-for="dates in info"
				:key="dates.id"
				>
					<td class="date-col" width="15%"><span class="date-number">{{ dates.dateN }}</span> <span class="date-day">{{ dates.dateD }}</span> <span class="date-month">{{ dates.dateM }}</span></td>
					<td class="location-col align-middle" width="60%"><span>{{ dates.ville }} ({{ dates.cp }})</span></td>
					<td class="comments-col align-middle" width="15%"><span>{{ dates.commentaire }}</span></td>
					<td class="align-middle" width="10%"><a v-bind:href="'https://www.google.fr/maps/place/'+ dates.cp + '+' + dates.ville" class="btn"><span class="btn-text">Map</span></a></td>
				</tr>
			</tbody>
		</table>

		<div class="table-mobile">
			<div class="dates-mobile"
				v-for="dates in info"
				:key="dates.id"
			>
				<div class="head-date-mobile">
					<span class="date-mobile">{{ dates.dateN }}<br></span>
					<span class="day-mobile">{{ dates.dateD }}</span>
					<span class="month-mobile">{{ dates.dateM }}</span>
				</div>
				<div class="place-mobile">
					<span class="city-mobile">{{ dates.ville }}</span> (<span class="cp_mobile">{{ dates.cp }}</span>)
				</div>
				<div class="comment-mobile">
					<span>{{ dates.commentaire }}</span>
				</div>
				<div class="map-mobile">
					<div class="text-center">
						<a v-bind:href="'https://www.google.fr/maps/place/'+ dates.cp + '+' + dates.ville" class="btn"><span class="btn-text">Map</span></a>
					</div>
				</div>
			</div>
		</div>
		
		<div class="container">
			<div class="row">
				<div class="col text-center">
					<router-link to="/Dates" class="btn text-center"><span class="btn-text">Voir toutes les dates</span></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Dates',
	data () {
		return {
			info: null
		}
	},
	mounted () {
		axios
		// .get(API+'/getLastDates')
		.get(process.env.VUE_APP_API_URL+'/getLastDates')
		.then(response => (this.info = response.data.data))
	}
}
</script>