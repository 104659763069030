<template>
<b-navbar id="navbar"  toggleable="lg" class="navbar navbar-expand-md flex-column fixed-top navbar-dark">
 <router-link class="navbar-brand align-self-center m-0 pb-3 position-md-absolute pb-md-0 nav-link" to="/"><img v-bind:src="'/img/logoblancc.png'"></router-link>
  <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
 <b-collapse id="nav-collapse" is-nav class=" navbar-collapse justify-content-md-center w-100">
  <b-navbar-nav>
   <b-nav-item>
    <router-link to="/Dates" class="nav-link">Dates</router-link>
   </b-nav-item>
   <b-nav-item>
    <router-link to="/Photos" class="nav-link">Photos</router-link>
   </b-nav-item>
   <b-nav-item >
    <router-link to="/Videos" class="nav-link">Vidéos</router-link>
   </b-nav-item>
   <b-nav-item >
    <router-link to="/Technique" class="nav-link">Technique</router-link>
   </b-nav-item>
   <b-nav-item >
    <router-link to="/Studio" class="nav-link">Studio</router-link>
   </b-nav-item>
   <b-nav-item >
    <router-link to="/Contact" class="nav-link">Contact</router-link>
   </b-nav-item>
  </b-navbar-nav>
  <b-navbar-nav right>
    <a href="https://www.facebook.com/Motel-Group-190149384444866/" target="_blank"><img class="social-header" v-bind:src="'/img/social/facebook.svg'"></a>
    <a href="https://www.youtube.com/channel/UCqr2EeyiFki_sNiVbqpCoqw" target="_blank"><img class="social-header" v-bind:src="'/img/social/youtube.svg'"></a>
  </b-navbar-nav>
 </b-collapse>
</b-navbar>
</template>

<script>

export default {
    name: 'Header'
}
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.getElementById("navbar").style.padding = "10px 0px"; // Quand scroll
    document.getElementById("navbar").style.background = "rgba(39, 39, 39, 0.7)";

  } else {
    document.getElementById("navbar").style.padding = "20px 0px"; // Initial
    document.getElementById("navbar").style.background = "rgba(39, 39, 39, 1)";
  }
}
</script>




<!-- <img v-bind:src="'/img/logoblancc.png'"> -->