<template>
	<div v-if="bio" id="biographie" class="container-fluid">
		<b-row>
			<b-col  lg="6" md="12">
				<h2><span style="font-size:30px;">l'Histoire de </span><br><b style="font-size:40px;">Motel</b></h2>
				<div class="divider-1"> <span></span></div>
				<span class="entete-bio">{{ bio.heading }}</span>
				<p style="white-space: pre-wrap;">{{ bio.text }}</p>
			</b-col>
			<b-col lg="6" md="12" class="pic_column">
				<img id="bio_pic" v-bind:src="url_admin+'/images/_biographie/'+ bio.url_image">
			</b-col>
		</b-row>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Biographie',
	data () {
		return {
			bio: null,
			url_admin: process.env.VUE_APP_ADMIN_URL
		}
	},
	mounted () {
		axios
		.get(process.env.VUE_APP_API_URL+'/getBiographie')
		.then(response => (this.bio = response.data.data[0]))
	}
}
</script>